import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaMedium, FaGithub} from "react-icons/fa";

let TeamContent = [
    {
        images: '01',
        title: 'Roberto Baldizon',
        designation: 'Developer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/b0bbybaldi'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/b0bbybaldi/'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/b0bbybaldi'
            },
            {
                icon: <FaInstagram />,
                url: 'https://instagram.com/@b0bbybaldi'
            },
            {
                icon: <FaMedium />,
                url: 'https://medium.com/b0bbybaldi'
            },
            {
                icon: <FaGithub />,
                url: 'https://github.com/b0bbybaldi'
            }
        ]
    },
    {
        images: '02',
        title: 'Pompilio Fiore',
        designation: 'Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/pifiore'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/pompiliofiore/'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/pifiore'
            },
            {
                icon: <FaInstagram />,
                url: 'https://instagram.com/pifiore'
            },
        ]
    }
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Team"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;